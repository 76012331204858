import { ParentSize } from '@visx/responsive';
import { scaleLinear } from '@visx/scale';
import { Line } from '@visx/shape';
import { Text } from '@visx/text';
import { select } from 'd3';
import { debounce, groupBy } from 'lodash';
import { Dispatch, SetStateAction, useContext, useRef } from 'react';
import { mostReadable } from 'tinycolor2';
import {
  defaultOpacity,
  defaultVoiceColor,
  fullOpacity,
  lowerOpacity,
  lowOpacity
} from './constants';
import MusicSheet from './models/MusicSheet';
import { areOccurrencesEqual, Occurrence, Pattern } from './models/Pattern';
import { Voice } from './models/Voice';
import PatternTooltip from './PatternTooltip';
import { SelectionContext } from './SelectionContext';
import { ThemeContext } from './ThemeContext';

export const timelineID = 'timeline';

const toSVG = (svg: SVGSVGElement, position: { x: number; y: number }) => {
  const domPoint = new DOMPoint(position.x, position.y);
  const transformMatrix = svg.getScreenCTM();
  const translatedPoint = domPoint.matrixTransform(transformMatrix.inverse());

  return { x: translatedPoint.x, y: translatedPoint.y };
};

const Timeline = ({
  absoluteOffsetPageMap,
  hoveredOccurrenceIndex,
  hoveredOccurrences,
  hoveredPatternIDs,
  patterns,
  selectedMusicSheet,
  setConfiguringID,
  setCurrentPage,
  setHoveredOccurrenceIndex,
  setHoveredPatternIDs,
  voices
}: {
  absoluteOffsetPageMap: Map<number, number>;
  hoveredOccurrenceIndex: number | undefined;
  hoveredOccurrences: Occurrence[];
  hoveredPatternIDs: string[];
  patterns: Pattern[];
  selectedMusicSheet: MusicSheet;
  setConfiguringID: Dispatch<SetStateAction<string | undefined>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setHoveredOccurrenceIndex: Dispatch<SetStateAction<number | undefined>>;
  setHoveredPatternIDs: Dispatch<SetStateAction<string[]>>;
  voices: Voice[];
}) => {
  const leftPadding = 50;
  const padding = 25;
  const voiceCount = voices.length;
  const voiceLineWidth = 1;

  return (
    <ParentSize className="mr-5" id={timelineID} parentSizeStyles={{ height: 175 }}>
      {(parent) => {
        const lineWidth = parent.width - leftPadding - padding;
        const marginBetweenVoices =
          (parent.height - 2 * padding - voiceCount * voiceLineWidth) / (voiceCount - 1);
        const offsetsByPage = groupBy(
          Array.from(absoluteOffsetPageMap.entries()),
          ([, page]) => page
        );
        const offsetScale = scaleLinear({
          domain: [0, selectedMusicSheet?.lastOffset],
          range: [0, lineWidth],
          round: true
        });
        const svg = useRef<SVGSVGElement>();

        if (selectedMusicSheet === undefined || absoluteOffsetPageMap.size === 0) {
          return (
            <div className="animate-pulse" style={{ height: 150 }}>
              <div className="w-full h-full mb-3 mt-3 rounded-md bg-gray-200"></div>
            </div>
          );
        } else {
          return (
            <svg
              height={parent.height}
              onMouseMove={(event) => {
                const { x } = toSVG(svg.current, { x: event.clientX, y: event.clientY });

                if (x >= leftPadding && x <= leftPadding + lineWidth) {
                  const offset = offsetScale.invert(x - leftPadding);
                  let hoveredPage = 1;

                  for (const [pageOffset, page] of absoluteOffsetPageMap.entries()) {
                    if (offset > pageOffset) {
                      hoveredPage = page;
                    } else {
                      break;
                    }
                  }

                  setCurrentPage(hoveredPage);
                }
              }}
              ref={svg}
              width={parent.width}
            >
              {Object.values(offsetsByPage).map((offsets, pageIndex) => {
                if (pageIndex === 0) return null;

                const firstPageOffset = offsets[0][0];
                const x = leftPadding + offsetScale(firstPageOffset);

                return (
                  <g key={pageIndex}>
                    <text fill="#808080" style={{ fontSize: 8, textAnchor: 'middle' }} x={x} y={6}>
                      Page {pageIndex + 1}
                    </text>
                    <Line
                      from={{ x, y: 12 }}
                      key={pageIndex}
                      style={{
                        stroke: 'grey',
                        strokeDasharray: 2,
                        strokeOpacity: defaultOpacity,
                        strokeWidth: voiceLineWidth
                      }}
                      to={{ x, y: parent.height - 10 }}
                    />
                  </g>
                );
              })}
              {voices.map((voice, index) => (
                <VoiceLine
                  hoveredOccurrenceIndex={hoveredOccurrenceIndex}
                  hoveredOccurrences={hoveredOccurrences}
                  hoveredPatternIDs={hoveredPatternIDs}
                  index={index}
                  key={voice.number}
                  lastOffset={selectedMusicSheet?.lastOffset}
                  leftPadding={leftPadding}
                  lineWidth={lineWidth}
                  marginBetweenVoices={marginBetweenVoices}
                  padding={padding}
                  parentWidth={parent.width}
                  patterns={patterns.filter((pattern) =>
                    pattern.occurrences.some(
                      (occurrence) => occurrence.voiceNumber === voice.number
                    )
                  )}
                  setConfiguringID={setConfiguringID}
                  setHoveredOccurrenceIndex={setHoveredOccurrenceIndex}
                  setHoveredPatternIDs={setHoveredPatternIDs}
                  voice={voice}
                  voiceLineWidth={voiceLineWidth}
                />
              ))}
            </svg>
          );
        }
      }}
    </ParentSize>
  );
};

const VoiceLine = ({
  hoveredOccurrenceIndex,
  hoveredOccurrences,
  hoveredPatternIDs,
  index,
  lastOffset,
  leftPadding,
  lineWidth,
  marginBetweenVoices,
  padding,
  parentWidth,
  patterns,
  setConfiguringID,
  setHoveredOccurrenceIndex,
  setHoveredPatternIDs,
  voice,
  voiceLineWidth
}: {
  hoveredOccurrenceIndex: number | undefined;
  hoveredOccurrences: Occurrence[];
  hoveredPatternIDs: string[];
  index: number;
  lastOffset: number | undefined;
  leftPadding: number;
  lineWidth: number;
  marginBetweenVoices: number;
  padding: number;
  parentWidth: number;
  patterns: Pattern[];
  setConfiguringID: Dispatch<SetStateAction<string | undefined>>;
  setHoveredOccurrenceIndex: Dispatch<SetStateAction<number | undefined>>;
  setHoveredPatternIDs: Dispatch<SetStateAction<string[]>>;
  voice: Voice;
  voiceLineWidth: number;
}) => {
  const [selection] = useContext(SelectionContext);
  const [theme] = useContext(ThemeContext);

  const color = theme.colorByVoice || theme.colorVoices ? voice.color : defaultVoiceColor;
  const label = `Voice ${voice.number}`;
  const offsetScale = scaleLinear({ domain: [0, lastOffset], range: [0, lineWidth], round: true });
  const textBackgroundCornerRadius = 2;
  const textHeight = 15;
  const textPadding = 5;
  const textWidth = 35;
  const y = padding + index * (voiceLineWidth + marginBetweenVoices);

  return (
    <g>
      <rect
        fill={color}
        fillOpacity={lowerOpacity}
        height={textHeight}
        rx={textBackgroundCornerRadius}
        ry={textBackgroundCornerRadius}
        width={textWidth + textPadding * 2}
        x={0}
        y={y - textHeight / 2}
      ></rect>
      <text
        fill={mostReadable(color, ['#000', '#808080', '#fff']).toHexString()}
        style={{ dominantBaseline: 'central', fontSize: 'xx-small' }}
        textLength={textWidth}
        x={textPadding}
        y={y}
      >
        {label}
      </text>
      <Line
        from={{ x: leftPadding, y }}
        key={voice.number}
        style={{ stroke: color, strokeOpacity: defaultOpacity, strokeWidth: voiceLineWidth }}
        to={{ x: parentWidth, y }}
      />
      {lastOffset
        ? patterns
            .filter(
              (pattern) =>
                pattern.isVisible &&
                (selection.focusingID === undefined || selection.focusingID === pattern.id)
            )
            .flatMap((pattern, patternIndex) =>
              pattern.occurrences
                .filter((occurrence) => occurrence.voiceNumber === voice.number)
                .map((occurrence, occurrenceIndex) => {
                  const globalOccurrenceIndex = pattern.occurrences.indexOf(occurrence);
                  const debouncedOnMouseEnter = debounce(() => {
                    setHoveredOccurrenceIndex(globalOccurrenceIndex);
                    setHoveredPatternIDs([pattern.id]);
                  }, 150);
                  const durationInOffsets = occurrence.lastOffset - occurrence.firstOffset;
                  const minimumOccurrenceWidthInOffsets = 2.5;
                  const occurrenceHeight = 20;
                  const occurrenceWidth = offsetScale(
                    Math.max(durationInOffsets, minimumOccurrenceWidthInOffsets)
                  );
                  const occurrenceX = leftPadding + offsetScale(occurrence.firstOffset);
                  const occurrenceY = y - occurrenceHeight / 2;
                  const shouldBeTransparent =
                    (hoveredPatternIDs.length > 0 && !hoveredPatternIDs.includes(pattern.id)) ||
                    (hoveredOccurrenceIndex !== undefined &&
                      globalOccurrenceIndex !== hoveredOccurrenceIndex) ||
                    (hoveredOccurrences.length > 0 &&
                      !hoveredOccurrences.some((hoveredOccurrence) =>
                        areOccurrencesEqual(occurrence, hoveredOccurrence)
                      ));

                  return (
                    <PatternTooltip
                      key={`${patternIndex}${occurrenceIndex}`}
                      pattern={pattern}
                      shouldDisplayDisclaimer={durationInOffsets < minimumOccurrenceWidthInOffsets}
                    >
                      <g
                        onMouseEnter={(event) => {
                          const group =
                            (event.target as SVGGElement).tagName === 'g'
                              ? (event.target as SVGGElement)
                              : (event.target as SVGGElement).parentElement;

                          select(group).raise();
                          debouncedOnMouseEnter();
                        }}
                        onMouseLeave={() => {
                          setHoveredOccurrenceIndex(undefined);
                          setHoveredPatternIDs([]);
                          debouncedOnMouseEnter.cancel();
                        }}
                        style={{ fillOpacity: shouldBeTransparent ? lowOpacity : fullOpacity }}
                      >
                        <rect
                          className="cursor-pointer"
                          fill={pattern.color}
                          height={occurrenceHeight}
                          onClick={() => setConfiguringID(pattern.id)}
                          rx={2}
                          ry={2}
                          width={occurrenceWidth}
                          x={occurrenceX}
                          y={occurrenceY}
                        ></rect>
                        <Text
                          fill={mostReadable(pattern.color, [
                            '#000',
                            '#808080',
                            '#fff'
                          ]).toHexString()}
                          fontSize="xx-small"
                          textAnchor="middle"
                          verticalAnchor="middle"
                          x={occurrenceX + occurrenceWidth / 2}
                          y={occurrenceY + occurrenceHeight / 2}
                        >
                          {pattern.label.length === 1 ||
                          (pattern.label.length === 2 && pattern.label.endsWith("'")) ||
                          (pattern.label.length === 3 && pattern.label.endsWith("''"))
                            ? pattern.label
                            : `${pattern.label.at(0)}...`}
                        </Text>
                      </g>
                    </PatternTooltip>
                  );
                })
            )
        : null}
    </g>
  );
};

export default Timeline;
