import { BulbOutlined, DeleteOutlined, DownloadOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Checkbox, Dropdown, Menu, Pagination, Select, Switch } from 'antd';
import blobStream from 'blob-stream';
import { saveAs } from 'file-saver';
import { groupBy } from 'lodash';
import PDFDocument from 'pdfkit';
import { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SVGtoPDF from 'svg-to-pdfkit';
import { getPageIDFromNumber } from './MelodyAnalysis';
import MusicSheet from './models/MusicSheet';
import { Pattern } from './models/Pattern';
import { ThemeContext } from './ThemeContext';

export const musicSheetSelectionID = 'music-sheet-selection';

const { Option, OptGroup } = Select;

const AlignedPagination = styled(Pagination)`
  .ant-pagination-item-link {
    line-height: 0;
  }
`;

export const AlignedSwitch = styled(Switch)`
  .ant-switch-inner {
    display: flex;
  }
`;

const MusicSheetSelection = ({
  currentPage,
  isMusicSheetRendered,
  musicSheets,
  numberOfPages,
  selectedMusicSheetID,
  setConfiguringID,
  setCurrentPage,
  setIsMusicSheetRendered,
  setNewPattern,
  setNumberOfPages,
  setPatterns,
  setSelectedMusicSheetID,
  setShowOnBoarding,
  setSuggestingID
}: {
  currentPage: number;
  isMusicSheetRendered: boolean;
  musicSheets: MusicSheet[];
  numberOfPages: number;
  selectedMusicSheetID: string;
  setConfiguringID: Dispatch<SetStateAction<string>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setIsMusicSheetRendered: Dispatch<SetStateAction<boolean>>;
  setNewPattern: Dispatch<SetStateAction<Pattern>>;
  setNumberOfPages: Dispatch<SetStateAction<number>>;
  setPatterns: Dispatch<SetStateAction<Pattern[]>>;
  setSelectedMusicSheetID: Dispatch<SetStateAction<string>>;
  setShowOnBoarding: Dispatch<SetStateAction<boolean>>;
  setSuggestingID: Dispatch<SetStateAction<string>>;
}) => {
  const [theme, setTheme] = useContext(ThemeContext);
  const { t: translate } = useTranslation();

  return (
    <div className="flex-1 flex items-center justify-evenly" id={musicSheetSelectionID}>
      <div>
        <Select
          className="w-[350]"
          dropdownMatchSelectWidth={false}
          onChange={(value) => {
            setIsMusicSheetRendered(false);
            setNumberOfPages(undefined);

            setSelectedMusicSheetID(value);
          }}
          size="large"
          value={selectedMusicSheetID}
        >
          {Object.entries(groupBy(musicSheets, 'category')).map(
            ([category, musicSheetsInCategory]: [string, [MusicSheet]]) => (
              <OptGroup
                key={category}
                label={translate(`musicSheets.${category}`, { defaultValue: category })}
              >
                {musicSheetsInCategory.map((musicSheet) => (
                  <Option
                    className="mb-4 font-bold tracking-tight text-gray-900"
                    key={musicSheet.id}
                    value={musicSheet.id}
                  >
                    <b>
                      {translate('musicSheets.title', {
                        composer: musicSheet.composer,
                        title: translate(`musicSheets.${musicSheet.title}`, {
                          defaultValue: musicSheet.title
                        })
                      })}
                    </b>
                  </Option>
                ))}
              </OptGroup>
            )
          )}
        </Select>
      </div>
      <AlignedPagination
        current={currentPage}
        defaultPageSize={1}
        disabled={!isMusicSheetRendered}
        onChange={(pageNumber) => setCurrentPage(pageNumber)}
        size="small"
        total={numberOfPages}
      />
      <div className="flex items-center">
        <Button
          className="mr-2"
          icon={<BulbOutlined />}
          onClick={() => setShowOnBoarding(true)}
          shape="circle"
          size="small"
        />
        <Button
          className="mr-2"
          icon={<DownloadOutlined />}
          onClick={() => {
            const selectedMusicSheet = musicSheets.find(
              (musicSheet) => musicSheet.id === selectedMusicSheetID
            );
            const pdfDocument = new PDFDocument({
              autoFirstPage: false,
              info: {
                Title: selectedMusicSheet.title,
                Subject: translate('melodyAnalysis')
              },
              size: 'A4'
            });
            const stream = pdfDocument.pipe(blobStream());

            for (let pageNumber = 1; pageNumber <= numberOfPages; pageNumber++) {
              const pageContent = document.querySelector(
                getPageIDFromNumber(pageNumber)
              ) as SVGElement;

              pdfDocument.addPage();
              SVGtoPDF(pdfDocument, pageContent.outerHTML, 0, 0, {
                preserveAspectRatio: 'xMidYMid meet'
              });
            }

            pdfDocument.end();
            stream.on('finish', function () {
              const blob = this.toBlob();

              saveAs(
                blob,
                `${translate(`musicSheets.${selectedMusicSheet.title}`, {
                  defaultValue: selectedMusicSheet.title
                })}.pdf`
              );
            });
          }}
          size="small"
          shape="circle"
        />
        <Button
          className="mr-2"
          danger
          icon={<DeleteOutlined />}
          onClick={() => {
            setConfiguringID(undefined);
            setNewPattern(undefined);
            setPatterns([]);
            setSuggestingID(undefined);
          }}
          size="small"
          shape="circle"
        />
        <Dropdown
          overlay={
            <Menu
              items={[
                {
                  key: 'color-by-voice',
                  label: (
                    <Checkbox
                      checked={theme.colorByVoice}
                      onChange={(event) =>
                        setTheme((oldTheme) => ({
                          ...oldTheme,
                          colorByVoice: event.target.checked,
                          colorVoices: oldTheme.colorVoices
                        }))
                      }
                    >
                      {translate('settings.colorByVoice')}
                    </Checkbox>
                  )
                },
                {
                  key: 'color-voices',
                  label: (
                    <Checkbox
                      checked={theme.colorVoices}
                      onChange={(event) =>
                        setTheme((oldTheme) => ({
                          ...oldTheme,
                          colorByVoice: oldTheme.colorByVoice,
                          colorVoices: event.target.checked
                        }))
                      }
                    >
                      {translate('settings.colorVoices')}
                    </Checkbox>
                  )
                }
              ]}
            />
          }
          placement="bottomRight"
          trigger={['click']}
        >
          <Button icon={<SettingOutlined />} size="small" shape="circle" />
        </Dropdown>
      </div>
    </div>
  );
};

export default MusicSheetSelection;
