import { blue } from '@ant-design/colors';
import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  RocketTwoTone,
  SearchOutlined
} from '@ant-design/icons';
import { Badge, Button, Checkbox, Popover, Slider, Spin, Tag, Tooltip, Typography } from 'antd';
import { isEqual } from 'lodash';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { NoteElementSelection } from './App';
import { defaultDeviationPercentage } from './constants';
import usePatterns from './hooks/usePatterns';
import { getIconForOperator, Operator, OperatorsInOrder, Pattern } from './models/Pattern';
import { SelectionContext } from './SelectionContext';

export const suggestionsID = 'suggestions';

const { CheckableTag } = Tag;
const { Title } = Typography;

const AlignedButton = styled(Button)`
  align-items: center;
  display: flex;
  justify-content: center;

  .ant-btn-loading-icon {
    display: flex;
  }
`;

const AlignedSpin = styled(Spin)`
  display: flex;

  .ant-spin {
    line-height: unset;
  }
`;

const CompactCheckableTag = styled(CheckableTag)`
  margin: 0 !important;
  margin-right: 0.25rem !important;
`;

const CompressedBadge = styled(Badge)`
  line-height: normal;
`;

const Suggestions = ({
  configuringID,
  hoveredPatternIDs,
  musicSheetID,
  newPattern,
  noteElements,
  patterns,
  setConfiguringID,
  setHoveredPatternIDs,
  setNewPattern,
  setPatterns,
  setSuggestingID,
  setVisibleOperatorsByDefault,
  suggestingID,
  visibleOperatorsByDefault
}: {
  configuringID: string;
  hoveredPatternIDs: string[];
  musicSheetID: string;
  newPattern: Pattern | undefined;
  noteElements: NoteElementSelection;
  patterns: Pattern[];
  setConfiguringID: Dispatch<SetStateAction<string>>;
  setHoveredPatternIDs: Dispatch<SetStateAction<string[]>>;
  setNewPattern: Dispatch<SetStateAction<Pattern | undefined>>;
  setPatterns: Dispatch<SetStateAction<Pattern[]>>;
  setSuggestingID: Dispatch<SetStateAction<string>>;
  setVisibleOperatorsByDefault: Dispatch<SetStateAction<Operator[]>>;
  suggestingID: string;
  visibleOperatorsByDefault: Operator[];
}) => {
  const [deviationPercentages, setDeviationPercentages] = useState<Map<string, number>>(new Map());
  const { loadingStatus, updateDeviationOperator } = usePatterns(
    musicSheetID,
    noteElements,
    newPattern,
    deviationPercentages.get(configuringID) || defaultDeviationPercentage,
    patterns,
    setNewPattern,
    setPatterns
  );
  const [selection] = useContext(SelectionContext);
  const [visibleOperators, setVisibleOperators] = useState<Map<string, Operator[]>>(new Map());
  const { t: translate } = useTranslation();

  const configuringPattern = patterns.find((pattern) => pattern.id === configuringID);
  const patternsConnected =
    patterns.find((pattern) => pattern.id === configuringID)?.connectedToByOperator.length || 0;

  const makePatternVisible = () =>
    setPatterns((oldPatterns) => {
      const oldPattern = oldPatterns.find(({ id }) => id === configuringID);

      if (oldPattern) {
        oldPattern.connectedToByOperator.forEach((target) => {
          const targetPattern = oldPatterns.find(({ id }) => id === target.id);

          if (targetPattern) {
            targetPattern.isVisible = visibleOperators
              .get(configuringID)
              .contains(target.byOperator);
          }
        });
      }

      return [...oldPatterns];
    });

  useEffect(() => {
    patterns.forEach((pattern) => {
      if (!deviationPercentages.has(pattern.id)) {
        setDeviationPercentages((oldDeviationPercentages) =>
          new Map(oldDeviationPercentages).set(pattern.id, defaultDeviationPercentage)
        );
      }

      if (!visibleOperators.has(pattern.id)) {
        setVisibleOperators((oldVisibleOperators) =>
          new Map(oldVisibleOperators).set(pattern.id, visibleOperatorsByDefault)
        );
      }
    });
  }, [patterns]);

  useEffect(() => {
    if (configuringPattern) {
      setPatterns((oldPatterns) => {
        const oldPattern = oldPatterns.find(({ id }) => id === configuringID);

        if (oldPattern) {
          oldPattern.connectedToByOperator.forEach(({ id: connectedPatternID, byOperator }) => {
            const connectedPattern = oldPatterns.find(({ id }) => id === connectedPatternID);

            if (connectedPattern) {
              connectedPattern.isVisible = visibleOperators.get(configuringID).contains(byOperator);
            }
          });
        }

        return [...oldPatterns];
      });
    }
  }, [visibleOperators]);

  return (
    <div className="mb-5 mt-5 pr-2.5 flex flex-col" id={suggestionsID}>
      <Title className="flex items-center" level={5}>
        <RocketTwoTone className="mr-1" twoToneColor="orange" />
        {translate('suggestions.title')}
      </Title>
      <div className="mb-8 mt-5 flex flex-col">
        <Checkbox
          checked={visibleOperators.get(configuringID)?.length === Object.values(Operator).length}
          className="!mb-2 !ml-1"
          disabled={
            !configuringPattern ||
            configuringPattern.connectedToByOperator.length === 0 ||
            Array.from(loadingStatus.values()).some((isLoading) => isLoading) ||
            selection.focusingID !== undefined
          }
          indeterminate={
            visibleOperators.get(configuringID)?.length > 0 &&
            visibleOperators.get(configuringID)?.length < Object.values(Operator).length
          }
          onChange={() => {
            setVisibleOperators((previouslyVisibleOperators) => {
              if (previouslyVisibleOperators.get(configuringID)?.length > 0) {
                previouslyVisibleOperators.set(configuringID, []);
              } else {
                previouslyVisibleOperators.set(
                  configuringID,
                  [...Object.values(Operator)].filter((operator) => {
                    const patternsConnectedByOperator = patterns
                      .find((pattern) => pattern.id === configuringID)
                      ?.connectedToByOperator.filter(
                        (source) => source.byOperator === operator
                      ).length;

                    return patternsConnectedByOperator > 0;
                  })
                );
              }

              return new Map(previouslyVisibleOperators);
            });
            makePatternVisible();
          }}
        >
          <div className="flex items-center">
            {translate('suggestions.operators.all.title')}
            <Badge
              className="!ml-1.5"
              count={patternsConnected}
              size="small"
              style={{ backgroundColor: '#1890ff' }}
            />
          </div>
        </Checkbox>
        {OperatorsInOrder.map((operator) => {
          const checkedStyles = 'border-blue-500 border-2';
          const disabledStyles = 'cursor-not-allowed opacity-25 hover:border-gray-300';
          const patternsConnectedByOperator =
            patterns
              .find((pattern) => pattern.id === configuringID)
              ?.connectedToByOperator.filter((source) => source.byOperator === operator).length ||
            0;

          const isAccepted = configuringID !== undefined && suggestingID === undefined;
          const isChecked = visibleOperators.get(configuringID)?.includes(operator);
          const isLoading = loadingStatus.has(operator) && loadingStatus.get(operator);
          const isDisabled =
            !configuringPattern ||
            patternsConnectedByOperator === 0 ||
            isLoading ||
            selection.focusingID !== undefined;

          return (
            <div
              className="grid mt-3"
              key={operator}
              onMouseEnter={() => {
                if (isDisabled) return;

                const hoveredPatternIDs = patterns
                  .find((pattern) => pattern.id === configuringID)
                  ?.connectedToByOperator.filter((target) => target.byOperator === operator)
                  .map((connection) => connection.id);

                setPatterns((oldPatterns) => {
                  oldPatterns.forEach((oldPattern) => {
                    if (hoveredPatternIDs.includes(oldPattern.id)) {
                      oldPattern.isVisible = true;
                    }
                  });

                  return [...oldPatterns];
                });
                setHoveredPatternIDs(hoveredPatternIDs);
              }}
              onMouseLeave={() => {
                if (isDisabled) return;

                setPatterns((oldPatterns) => {
                  oldPatterns.forEach((oldPattern) => {
                    if (hoveredPatternIDs.includes(oldPattern.id)) {
                      oldPattern.isVisible = isChecked;
                    }
                  });

                  setHoveredPatternIDs([]);

                  return [...oldPatterns];
                });
              }}
              style={{ gridTemplateColumns: 'fit-content(20%) fit-content(80%)' }}
            >
              <CompressedBadge
                className="!mr-2"
                color="blue"
                count={patternsConnectedByOperator}
                offset={[0, 1]}
                size="small"
              >
                <img
                  alt={operator}
                  className={`p-0.5 border border-solid border-gray-300 hover:border-blue-500 rounded cursor-pointer ${
                    isChecked && checkedStyles
                  } ${isDisabled && disabledStyles}`}
                  height={24}
                  onClick={() => {
                    if (isDisabled) return;

                    setPatterns((oldPatterns) => {
                      const oldPattern = oldPatterns.find(({ id }) => id === configuringID);

                      if (oldPattern) {
                        oldPattern.connectedToByOperator.forEach((target) => {
                          const targetPattern = oldPatterns.find(({ id }) => id === target.id);

                          if (targetPattern) targetPattern.isVisible = !isChecked;
                        });
                      }

                      return [...oldPatterns];
                    });
                    setVisibleOperators((previouslyVisibleOperators) => {
                      const newVisibleOperators = isChecked
                        ? previouslyVisibleOperators
                            .get(configuringID)
                            .filter((visibleOperator) => visibleOperator !== operator)
                        : previouslyVisibleOperators.get(configuringID).concat(operator);

                      previouslyVisibleOperators.set(configuringID, newVisibleOperators);

                      return new Map(previouslyVisibleOperators);
                    });
                  }}
                  src={getIconForOperator(operator)}
                  width={24}
                />
              </CompressedBadge>
              <div className="flex flex-col leading-none" style={{ fontSize: 'small' }}>
                <div className="flex items-center">
                  <span className="font-bold">
                    {translate(`suggestions.operators.${operator}.title`)}
                  </span>
                  {isLoading && (
                    <AlignedSpin
                      className="!ml-1"
                      indicator={<LoadingOutlined style={{ fontSize: 'small' }} spin />}
                    />
                  )}
                </div>
                <span className="mt-0.5">
                  {operator === Operator.DEVIATION ? (
                    <Trans
                      components={[
                        <ChangeableDeviationPercentage
                          deviationPercentage={
                            deviationPercentages.get(configuringID) || defaultDeviationPercentage
                          }
                          isDisabled={isDisabled || isAccepted}
                          setDeviationPercentage={(newDeviationPercentage: number) => {
                            setDeviationPercentages((oldDeviationPercentages) =>
                              new Map(oldDeviationPercentages).set(
                                configuringID,
                                newDeviationPercentage
                              )
                            );
                            updateDeviationOperator(configuringID, newDeviationPercentage);
                          }}
                        />
                      ]}
                      i18nKey={`suggestions.operators.${operator}.description`}
                      values={{ deviationPercentage: deviationPercentages }}
                    ></Trans>
                  ) : (
                    translate(`suggestions.operators.${operator}.description`)
                  )}
                </span>
              </div>
            </div>
          );
        })}
        <div className="mt-6 flex items-center justify-center text-xs">
          <CompactCheckableTag
            checked={isEqual(visibleOperatorsByDefault, visibleOperators.get(configuringID))}
            onChange={(checked) =>
              checked && setVisibleOperatorsByDefault(visibleOperators.get(configuringID))
            }
          >
            {translate('suggestions.useAsDefault')}
          </CompactCheckableTag>
          <Tooltip title={translate('suggestions.useAsDefaultTooltip')}>
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      </div>
      {suggestingID !== undefined && configuringPattern?.isUserDefined && (
        <>
          <AlignedButton
            className="mb-1 self-center"
            icon={<CheckOutlined />}
            loading={Array.from(loadingStatus.values()).some((isLoading) => isLoading)}
            onClick={() => {
              makePatternVisible();
              setConfiguringID(undefined);
              setNewPattern(undefined);
              setSuggestingID(undefined);
            }}
            size="small"
            type="primary"
          >
            {translate('suggestions.accept')}
          </AlignedButton>
          <AlignedButton
            className="self-center"
            danger
            icon={<CloseOutlined />}
            loading={Array.from(loadingStatus.values()).some((isLoading) => isLoading)}
            onClick={() => {
              setPatterns((oldPatterns) => {
                const oldPattern = oldPatterns.find(({ id }) => id === suggestingID);
                const oldPatternIndex = oldPatterns.findIndex(({ id }) => id === suggestingID);

                if (oldPattern && oldPatternIndex !== -1) {
                  oldPattern.connectedToByOperator.forEach(({ id: connectedID }) => {
                    const oldConnectedPatternIndex = oldPatterns.findIndex(
                      ({ id }) => id === connectedID
                    );

                    if (oldConnectedPatternIndex !== -1)
                      oldPatterns.splice(oldConnectedPatternIndex, 1);
                  });
                  oldPatterns.splice(oldPatternIndex, 1);
                }

                return [...oldPatterns];
              });
              setVisibleOperators((previouslyVisibleOperators) => {
                previouslyVisibleOperators.delete(suggestingID);

                return new Map(previouslyVisibleOperators);
              });
              setConfiguringID(undefined);
              setNewPattern(undefined);
              setSuggestingID(undefined);
            }}
            size="small"
            type="primary"
          >
            {translate('suggestions.decline')}
          </AlignedButton>
        </>
      )}
      {suggestingID === undefined &&
        configuringID !== undefined &&
        !configuringPattern?.isUserDefined && (
          <>
            <AlignedButton
              className="self-center"
              disabled={configuringPattern.hasBeenQueried}
              icon={<SearchOutlined />}
              onClick={() => setNewPattern(configuringPattern)}
              size="small"
              type="primary"
            >
              {translate('suggestions.generate')}
            </AlignedButton>
          </>
        )}
    </div>
  );
};

const ChangeableDeviationPercentage = ({
  deviationPercentage,
  isDisabled,
  setDeviationPercentage
}: {
  deviationPercentage: number;
  isDisabled: boolean;
  setDeviationPercentage: (deviationPercentage: number) => void;
}) => {
  const maximum = 50;
  const minimum = 5;

  const { t: translate } = useTranslation();

  return (
    <Popover
      content={() => (
        <div>
          <div className="flex items-center">
            <span className="flex-1 mr-1 text-gray-400 text-xs whitespace-nowrap">
              <i>{minimum} %</i>
            </span>
            <Slider
              autoFocus
              className="flex-4 w-[90%]"
              defaultValue={deviationPercentage}
              disabled={isDisabled}
              max={maximum}
              min={minimum}
              onAfterChange={setDeviationPercentage}
            />
            <span className="flex-1 ml-1 text-gray-400 text-xs whitespace-nowrap">
              <i>{maximum} %</i>
            </span>
          </div>
          <span
            className="block pb-1.5 text-gray-500 leading-none"
            style={{ fontSize: 'xx-small' }}
          >
            {translate('suggestions.operators.deviation.disclaimer')}
          </span>
        </div>
      )}
      id="deviation-percentage-slider"
      overlayClassName="w-[250px]"
      trigger="click"
    >
      <span className="cursor-pointer font-bold" style={{ color: blue.primary }}>
        {deviationPercentage} %
      </span>
    </Popover>
  );
};

export default Suggestions;
