import { Layout, Segmented } from 'antd';
import { Selection } from 'd3';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import germanFlag from '../assets/images/languages/de.png';
import englishFlag from '../assets/images/languages/us.png';
import useMusicSheet from './hooks/useMusicSheet';
import useMusicSheets from './hooks/useMusicSheets';
import { occursFirstInVoice } from './hooks/usePatterns';
import useVoices from './hooks/useVoices';
import MelodyAnalysis, { BeamStemElementSelection } from './MelodyAnalysis';
import MelodyGraph from './MelodyGraph';
import { NoteContext } from './models/Note';
import { Occurrence, Operator, Pattern } from './models/Pattern';
import MusicSheetSelection from './MusicSheetSelection';
import OnBoarding from './OnBoarding';
import Suggestions from './Suggestions';
import { ThemeContext } from './ThemeContext';
import Timeline from './Timeline';

export type NoteElementSelection = Selection<SVGGElement, NoteContext, null, undefined>;

const { Content, Footer } = Layout;

const AlignedSegmented = styled(Segmented)`
  .ant-segmented-item-label {
    align-items: center;
    display: flex;
  }
`;

const FullHeightLayout = styled(Layout)`
  height: 100%;
`;

const WhiteFooter = styled(Footer)`
  align-items: center;
  background-color: white;
  display: flex;
  font-size: x-small;
  padding: 0;
`;

export const mainWrapperID = 'main-wrapper';

const App = () => {
  const [absoluteOffsetPageMap, setAbsoluteOffsetPageMap] = useState<Map<number, number>>(
    new Map()
  );
  const [configuringID, setConfiguringID] = useState<string>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hoveredOccurrenceIndex, setHoveredOccurrenceIndex] = useState<number>(undefined);
  const [hoveredOccurrences, setHoveredOccurrences] = useState<Occurrence[]>([]);
  const [hoveredPatternIDs, setHoveredPatternIDs] = useState<string[]>([]);
  const [isMusicSheetRendered, setIsMusicSheetRendered] = useState<boolean>(false);
  const [newPattern, setNewPattern] = useState<Pattern | undefined>(undefined);
  const [noteBeamElements, setNoteBeamElements] = useState<BeamStemElementSelection>();
  const [noteElements, setNoteElements] = useState<NoteElementSelection>();
  const [noteStemElements, setNoteStemElements] = useState<BeamStemElementSelection>();
  const [numberOfPages, setNumberOfPages] = useState<number>();
  const [patterns, setPatterns] = useState<Pattern[]>([]);
  const [selectedMusicSheetID, setSelectedMusicSheetID] = useState<string>();
  const selectedMusicSheet = useMusicSheet(selectedMusicSheetID);
  const [showOnBoarding, setShowOnBoarding] = useState<boolean>(false);
  const [suggestingID, setSuggestionsID] = useState<string>(undefined);
  const [theme, setTheme] = useContext(ThemeContext);
  const [visibleOperatorsByDefault, setVisibleOperatorsByDefault] = useState<Operator[]>([
    Operator.TRANSPOSITION
  ]);

  const { i18n } = useTranslation();
  const musicSheets = useMusicSheets();
  const voices = useVoices(selectedMusicSheetID);

  useEffect(() => {
    if (theme.colorByVoice) {
      // Retain the current colors of the patterns to re-use them after coloring by pattern, again.
      setTheme((oldTheme) => ({
        ...oldTheme,
        availablePatternColors: [
          ...oldTheme.availablePatternColors,
          ...patterns.map((pattern) => pattern.color).reverse()
        ]
      }));

      setPatterns((oldPatterns) => {
        oldPatterns.forEach(
          (oldPattern) => (oldPattern.color = theme.getVoiceColor(occursFirstInVoice(oldPattern)))
        );

        return [...oldPatterns];
      });
    } else {
      setPatterns((oldPatterns) => {
        oldPatterns.forEach((oldPattern) => {
          oldPattern.color = theme.availablePatternColors.pop();
        });

        return [...oldPatterns];
      });
    }
  }, [theme.colorByVoice]);

  useEffect(() => {
    if (
      absoluteOffsetPageMap.size > 0 &&
      (hoveredPatternIDs.length > 0 || hoveredOccurrences.length > 0) &&
      patterns.length > 0
    ) {
      let firstOffset;
      const hoveredPatterns = patterns.filter((pattern) => hoveredPatternIDs.includes(pattern.id));

      if (hoveredOccurrenceIndex !== undefined && hoveredPatterns.length === 1) {
        const hoveredOccurrence = hoveredPatterns[0].occurrences[hoveredOccurrenceIndex];

        firstOffset = hoveredOccurrence.firstOffset;
      } else if (hoveredOccurrences.length > 0) {
        firstOffset = Math.min(...hoveredOccurrences.map((occurrence) => occurrence.firstOffset));
      } else {
        firstOffset = Math.min(
          ...hoveredPatterns.flatMap((hoveredPattern) =>
            hoveredPattern.occurrences.map((occurrence) => occurrence.firstOffset)
          )
        );
      }

      const occurrencePage = absoluteOffsetPageMap.get(firstOffset);
      if (occurrencePage !== undefined && occurrencePage !== currentPage) {
        setCurrentPage(occurrencePage);
      }
    }
  }, [absoluteOffsetPageMap, hoveredOccurrenceIndex, hoveredOccurrences, hoveredPatternIDs]);

  return (
    <FullHeightLayout>
      <Content className="p-5 bg-white">
        <OnBoarding isVisible={showOnBoarding} setIsVisible={setShowOnBoarding} />
        <div
          className="h-full grid gap-x-6"
          id={mainWrapperID}
          style={{ gridTemplateColumns: 'repeat(2, calc(50% - 0.75rem)' }}
        >
          <div className="flex flex-col justify-between">
            <MusicSheetSelection
              currentPage={currentPage}
              isMusicSheetRendered={isMusicSheetRendered}
              musicSheets={musicSheets}
              numberOfPages={numberOfPages}
              selectedMusicSheetID={selectedMusicSheetID}
              setConfiguringID={setConfiguringID}
              setCurrentPage={setCurrentPage}
              setIsMusicSheetRendered={setIsMusicSheetRendered}
              setNewPattern={setNewPattern}
              setNumberOfPages={setNumberOfPages}
              setPatterns={setPatterns}
              setSelectedMusicSheetID={setSelectedMusicSheetID}
              setShowOnBoarding={setShowOnBoarding}
              setSuggestingID={setSuggestionsID}
            />
            <MelodyAnalysis
              currentPage={currentPage}
              hoveredOccurrenceIndex={hoveredOccurrenceIndex}
              hoveredOccurrences={hoveredOccurrences}
              hoveredPatternIDs={hoveredPatternIDs}
              isMusicSheetRendered={isMusicSheetRendered}
              musicSheets={musicSheets}
              noteBeamElements={noteBeamElements}
              noteElements={noteElements}
              noteStemElements={noteStemElements}
              numberOfPages={numberOfPages}
              patterns={patterns}
              selectedMusicSheet={selectedMusicSheet}
              setAbsoluteOffsetPageMap={setAbsoluteOffsetPageMap}
              setConfiguringID={setConfiguringID}
              setCurrentPage={setCurrentPage}
              setHoveredOccurrenceIndex={setHoveredOccurrenceIndex}
              setHoveredPatternIDs={setHoveredPatternIDs}
              setIsMusicSheetRendered={setIsMusicSheetRendered}
              setNewPattern={setNewPattern}
              setNoteBeamElements={setNoteBeamElements}
              setNoteElements={setNoteElements}
              setNoteStemElements={setNoteStemElements}
              setNumberOfPages={setNumberOfPages}
              setPatterns={setPatterns}
              setSelectedMusicSheetID={setSelectedMusicSheetID}
              setSuggestingID={setSuggestionsID}
              suggestingID={suggestingID}
              voices={voices}
            />
          </div>
          <div className="flex flex-col justify-between">
            <Timeline
              absoluteOffsetPageMap={absoluteOffsetPageMap}
              hoveredOccurrenceIndex={hoveredOccurrenceIndex}
              hoveredOccurrences={hoveredOccurrences}
              hoveredPatternIDs={hoveredPatternIDs}
              patterns={patterns}
              selectedMusicSheet={selectedMusicSheet}
              setConfiguringID={setConfiguringID}
              setCurrentPage={setCurrentPage}
              setHoveredOccurrenceIndex={setHoveredOccurrenceIndex}
              setHoveredPatternIDs={setHoveredPatternIDs}
              voices={voices}
            />
            <div
              className="grid grid-cols-[fit-content(175px)_4fr] grid-x-2"
              style={{ height: '-webkit-fill-available' }}
            >
              <Suggestions
                configuringID={configuringID}
                hoveredPatternIDs={hoveredPatternIDs}
                musicSheetID={selectedMusicSheetID}
                noteElements={noteElements}
                newPattern={newPattern}
                patterns={patterns}
                setConfiguringID={setConfiguringID}
                setHoveredPatternIDs={setHoveredPatternIDs}
                setNewPattern={setNewPattern}
                setPatterns={setPatterns}
                setSuggestingID={setSuggestionsID}
                setVisibleOperatorsByDefault={setVisibleOperatorsByDefault}
                suggestingID={suggestingID}
                visibleOperatorsByDefault={visibleOperatorsByDefault}
              />
              <MelodyGraph
                configuringID={configuringID}
                hoveredOccurrences={hoveredOccurrences}
                hoveredPatternIDs={hoveredPatternIDs}
                patterns={patterns}
                setConfiguringID={setConfiguringID}
                setHoveredOccurrenceIndex={setHoveredOccurrenceIndex}
                setHoveredOccurrences={setHoveredOccurrences}
                setHoveredPatternIDs={setHoveredPatternIDs}
                setPatterns={setPatterns}
                setSuggestingID={setSuggestionsID}
              />
            </div>
            <WhiteFooter className="justify-end" style={{ height: '3vh' }}>
              <AlignedSegmented
                onChange={(language) => i18n.changeLanguage(language as string)}
                options={[
                  {
                    icon: <img alt="The German flag" src={germanFlag} title="Deutsch" width={16} />,
                    value: 'de'
                  },
                  {
                    icon: (
                      <img
                        alt="The flag of the United States of America"
                        src={englishFlag}
                        title="English"
                        width={16}
                      />
                    ),
                    value: 'en'
                  }
                ]}
                size="small"
                value={i18n.languages[0]}
              />
              {/* <span className="ml-3 mr-5">&copy; {new Date().getFullYear()} Daniel Fürst</span> */}
              {/* <span className="ml-3 mr-5">&copy; {new Date().getFullYear()} Matthias Miller, Daniel Fürst, Maximilian T. Fischer, Hanna Hauptmann, Daniel Keim, and Mennatallah El-Assady</span>               */}
            </WhiteFooter>
          </div>
        </div>
      </Content>
    </FullHeightLayout>
  );
};

export default App;
