import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';
import tinycolor from 'tinycolor2';

const patternColors = [
  '#FF9489',
  '#8EC9FF',
  '#ACFFAE',
  '#FFFBAA',
  '#FFD8E5',
  '#C7A8FF',
  '#FFC58A',
  '#8EFFD7',
  '#FFD1B3',
  '#FFDE8E',
  '#FFC0CB',
  '#C4B4FF',
  '#C3C9FF',
  '#FFE8C9',
  '#FDFDBD',
  '#C8FFD4',
  '#B8E8FC',
  '#CDE990',
  '#B1AFFF',
  '#FFD4D4',
  '#AACB73',
  '#E49393',
  '#B8E8FC',
  '#CDE990',
  '#B1AFFF',
  '#FFD4D4',
  '#F7C5E1',
  '#AEE6E6',
  '#FFB5E8',
  '#D5AAFF',
  '#FFA2A2',
  '#D7FFAB',
  '#A3D3FF',
  '#FFCCEB',
  '#E1A1FF',
  '#FFE8A2',
  '#A3FFEB',
  '#FFA8D7',
  '#E1FFA1',
  '#A2C8FF',
  '#FFD5E5',
  '#AFD5A1',
  '#C2A2FF',
  '#FFA8A8',
  '#A2FFC8',
  '#FFB6C1',
  '#D0D5FF',
  '#FFC8A2',
  '#A1FFE1',
  'orange',
  'darkred',
  'cyan',
  'darkorchid',
  'lightpink',
  'red',
  'yellow',
  'lime',
  'magenta',
  'olive',
  'darkkhaki',
  'rgb(177,191,78)',
  'rgb(103,182,118)',
  'rgb(92,178,184)',
  'rgb(186,95,65)',
  'rgb(85,55,138)',
  'rgb(100,70,165)',
  'rgb(92,59,124)',
  'rgb(141,65,178)',
  'rgb(87,122,194)',
  'rgb(60,151,117)',
  'rgb(74,106,90)',
  'rgb(125,105,114)',
  'rgb(84,118,147)',
  'rgb(150,97,199)',
  'rgb(185,76,155)',
  'rgb(154,96,77)',
  'rgb(63,73,113)',
  'rgb(191,57,66)',
  'rgb(154,74,157)',
  'rgb(132,68,76)',
  'rgb(65,103,179)',
  'rgb(157,113,185)',
  'rgb(132,184,197)',
  'rgb(149,198,185)',
  'rgb(183,188,98)',
  'rgb(92,86,79)',
  'rgb(58,172,55)',
  'rgb(194,117,193)',
  'rgb(133,70,99)',
  'rgb(195,159,193)',
  'rgb(75,194,123)',
  'rgb(82,199,184)',
  'rgb(51,51,176)',
  'rgb(66,181,167)',
  'rgb(105,111,115)',
  'rgb(183,136,106)',
  'rgb(86,139,127)',
  'rgb(192,134,61)',
  'rgb(104,181,71)',
  'rgb(99,184,116)',
  'rgb(56,145,192)',
  'rgb(72,100,152)',
  'rgb(156,169,128)',
  'rgb(179,111,53)',
  'rgb(179,181,72)',
  'rgb(107,171,100)',
  'rgb(128,57,160)',
  'rgb(56,112,196)',
  'rgb(146,190,61)',
  'rgb(94,109,74)',
  'rgb(63,199,79)',
  'rgb(118,108,122)',
  'rgb(126,195,191)',
  'rgb(169,95,103)',
  'rgb(199,144,70)',
  'rgb(164,111,139)',
  'rgb(123,175,161)',
  'rgb(83,66,97)',
  'rgb(197,193,122)',
  'rgb(106,93,157)',
  'rgb(113,173,59)',
  'rgb(83,172,55)',
  'rgb(121,176,62)',
  'rgb(159,135,179)',
  'rgb(111,194,87)',
  'rgb(51,180,160)',
  'rgb(176,55,98)',
  'rgb(123,152,94)',
  'rgb(110,191,167)',
  'rgb(73,93,64)',
  'rgb(145,168,165)',
  'rgb(188,99,149)',
  'rgb(178,135,172)',
  'rgb(85,174,131)',
  'rgb(155,146,143)',
  'rgb(192,67,80)',
  'rgb(78,159,196)',
  'rgb(111,65,77)',
  'rgb(63,73,61)',
  'rgb(58,164,147)',
  'rgb(114,151,85)',
  'rgb(113,79,186)',
  'rgb(112,195,60)',
  'rgb(156,129,173)',
  'rgb(92,55,185)',
  'rgb(108,162,77)',
  'rgb(99,185,171)',
  'rgb(121,162,105)',
  'rgb(62,153,159)',
  'rgb(189,79,133)',
  'rgb(137,158,53)',
  'rgb(66,143,127)',
  'rgb(171,63,98)',
  'rgb(149,137,157)',
  'rgb(143,177,66)',
  'rgb(92,55,148)',
  'rgb(81,129,195)',
  'rgb(77,157,152)',
  'rgb(150,175,107)',
  'rgb(71,115,81)',
  'rgb(108,106,144)',
  'rgb(72,52,105)',
  'rgb(72,52,109)',
  'rgb(165,83,64)',
  'rgb(127,129,73)',
  'rgb(193,50,195)',
  'rgb(162,160,55)',
  'rgb(110,184,146)',
  'rgb(107,54,141)',
  'rgb(180,155,166)',
  'rgb(125,127,111)',
  'rgb(137,50,191)',
  'rgb(126,133,52)',
  'rgb(90,190,88)',
  'rgb(68,129,122)',
  'rgb(113,108,86)',
  'rgb(94,117,146)',
  'rgb(111,77,55)',
  'rgb(195,191,77)',
  'rgb(51,73,190)',
  'rgb(139,153,115)',
  'rgb(137,87,177)',
  'rgb(74,183,194)',
  'rgb(170,89,84)',
  'rgb(197,79,63)',
  'rgb(169,182,140)',
  'rgb(84,107,135)',
  'rgb(160,64,191)',
  'rgb(152,197,198)',
  'rgb(62,171,163)',
  'rgb(194,124,130)',
  'rgb(147,83,81)',
  'rgb(124,133,61)',
  'rgb(165,151,85)',
  'rgb(88,189,95)',
  'rgb(80,194,116)',
  'rgb(75,183,162)',
  'rgb(192,186,174)',
  'rgb(79,169,136)',
  'rgb(73,84,117)',
  'rgb(57,83,150)',
  'rgb(133,91,65)',
  'rgb(197,146,93)',
  'rgb(51,120,108)',
  'rgb(73,171,90)',
  'rgb(81,133,139)',
  'rgb(164,116,75)',
  'rgb(196,154,89)',
  'rgb(97,176,178)',
  'rgb(158,144,129)',
  'rgb(58,172,186)',
  'rgb(72,185,132)',
  'rgb(107,86,70)',
  'rgb(111,117,132)',
  'rgb(196,102,100)',
  'rgb(128,66,144)',
  'rgb(185,66,181)',
  'rgb(110,140,52)',
  'rgb(170,115,77)',
  'rgb(53,172,77)',
  'rgb(132,76,127)',
  'rgb(190,92,99)',
  'rgb(194,131,154)',
  'rgb(179,198,72)',
  'rgb(146,196,153)',
  'rgb(123,99,199)',
  'rgb(81,199,196)',
  'rgb(189,58,68)',
  'rgb(82,86,81)',
  'rgb(176,123,106)',
  'rgb(135,175,112)',
  'rgb(50,95,143)',
  'rgb(160,60,138)',
  'rgb(151,87,184)',
  'rgb(109,135,115)',
  'rgb(80,191,79)',
  'rgb(196,69,127)',
  'rgb(52,178,99)',
  'rgb(138,91,145)',
  'rgb(164,55,197)',
  'rgb(155,64,89)',
  'rgb(99,93,97)',
  'rgb(51,144,127)',
  'rgb(160,180,156)',
  'rgb(141,171,141)',
  'rgb(164,93,93)',
  'rgb(61,57,123)',
  'rgb(88,169,186)',
  'rgb(63,127,83)',
  'rgb(106,90,183)',
  'rgb(137,188,135)',
  'rgb(70,91,144)',
  'rgb(176,137,162)',
  'rgb(106,133,77)',
  'rgb(198,131,68)',
  'rgb(102,66,103)',
  'rgb(86,124,180)',
  'rgb(56,134,154)',
  'rgb(172,184,104)',
  'rgb(130,164,117)',
  'rgb(126,63,65)',
  'rgb(185,109,98)',
  'rgb(98,134,68)',
  'rgb(139,173,163)',
  'rgb(142,76,156)',
  'rgb(64,94,199)',
  'rgb(172,133,94)',
  'rgb(157,186,191)',
  'rgb(165,61,186)',
  'rgb(57,165,188)',
  'rgb(172,86,115)',
  'rgb(61,106,184)',
  'rgb(172,52,189)',
  'rgb(59,160,96)',
  'rgb(179,195,83)',
  'rgb(109,78,181)',
  'rgb(109,82,131)',
  'rgb(133,197,119)',
  'rgb(101,118,180)',
  'rgb(171,66,51)',
  'rgb(54,91,52)',
  'rgb(119,84,134)',
  'rgb(185,88,192)',
  'rgb(194,197,157)',
  'rgb(176,67,51)',
  'rgb(108,199,57)',
  'rgb(199,97,186)',
  'rgb(106,161,107)',
  'rgb(146,132,134)',
  'rgb(166,153,100)',
  'rgb(97,125,134)',
  'rgb(146,194,89)',
  'rgb(69,53,177)',
  'rgb(95,82,73)',
  'rgb(190,183,138)',
  'rgb(66,132,74)',
  'rgb(153,78,57)',
  'rgb(89,123,114)',
  'rgb(139,92,161)',
  'rgb(56,151,126)',
  'rgb(80,132,112)',
  'rgb(139,92,139)',
  'rgb(88,111,50)',
  'rgb(157,152,57)',
  'rgb(84,101,183)',
  'rgb(79,183,57)',
  'rgb(117,51,79)',
  'rgb(194,103,118)',
  'rgb(94,195,136)',
  'rgb(114,187,190)',
  'rgb(101,124,109)',
  'rgb(164,152,89)',
  'rgb(191,59,127)',
  'rgb(129,105,141)',
  'rgb(83,107,164)',
  'rgb(150,102,93)',
  'rgb(103,119,71)',
  'rgb(174,105,61)',
  'rgb(180,131,164)',
  'rgb(136,85,156)',
  'rgb(183,124,137)',
  'rgb(150,91,122)',
  'rgb(104,94,124)',
  'rgb(193,146,50)',
  'rgb(150,165,86)',
  'rgb(177,199,75)',
  'rgb(54,82,195)',
  'rgb(168,118,79)',
  'rgb(190,58,182)',
  'rgb(84,181,173)',
  'rgb(65,170,100)',
  'rgb(85,193,63)',
  'rgb(142,55,88)',
  'rgb(139,88,127)',
  'rgb(135,71,72)',
  'rgb(107,131,122)',
  'rgb(184,165,72)',
  'rgb(122,160,94)',
  'rgb(175,145,97)',
  'rgb(133,151,65)',
  'rgb(63,188,85)',
  'rgb(190,135,58)',
  'rgb(121,148,130)',
  'rgb(120,108,186)',
  'rgb(78,128,197)',
  'rgb(121,121,153)',
  'rgb(138,95,72)',
  'rgb(161,140,171)',
  'rgb(117,99,158)',
  'rgb(159,63,180)',
  'rgb(56,141,185)',
  'rgb(132,159,185)',
  'rgb(172,169,195)',
  'rgb(85,55,192)',
  'rgb(107,185,179)',
  'rgb(167,145,138)',
  'rgb(122,190,103)',
  'rgb(177,189,101)',
  'rgb(193,187,59)',
  'rgb(150,187,82)',
  'rgb(181,86,176)',
  'rgb(157,150,178)',
  'rgb(91,171,75)',
  'rgb(147,153,110)',
  'rgb(69,146,160)',
  'rgb(190,179,79)',
  'rgb(134,55,88)',
  'rgb(65,145,86)',
  'rgb(112,158,95)',
  'rgb(64,73,71)',
  'rgb(139,90,97)',
  'rgb(108,52,186)',
  'rgb(150,111,98)',
  'rgb(132,82,197)',
  'rgb(158,57,167)',
  'rgb(142,81,70)',
  'rgb(176,198,99)',
  'rgb(121,60,95)',
  'rgb(188,53,98)',
  'rgb(163,111,88)',
  'rgb(142,182,62)',
  'rgb(97,165,181)',
  'rgb(189,165,70)',
  'rgb(67,130,88)',
  'rgb(128,199,197)',
  'rgb(122,59,141)',
  'rgb(130,129,82)',
  'rgb(163,53,174)',
  'rgb(117,185,79)',
  'rgb(160,134,146)',
  'rgb(114,87,138)',
  'rgb(91,90,83)',
  'rgb(95,137,141)',
  'rgb(72,189,118)',
  'rgb(95,131,145)',
  'rgb(51,56,121)',
  'rgb(189,140,195)',
  'rgb(146,71,154)',
  'rgb(151,138,68)',
  'rgb(52,81,63)',
  'rgb(120,79,166)',
  'rgb(89,196,162)',
  'rgb(110,139,197)',
  'rgb(61,169,88)',
  'rgb(102,157,110)',
  'rgb(99,178,62)',
  'rgb(74,105,146)',
  'rgb(51,179,175)',
  'rgb(163,129,92)',
  'rgb(171,188,166)',
  'rgb(177,155,177)',
  'rgb(151,78,148)',
  'rgb(125,78,176)',
  'rgb(59,96,178)',
  'rgb(127,176,141)',
  'rgb(83,180,116)',
  'rgb(115,160,78)',
  'rgb(198,188,62)',
  'rgb(155,72,92)',
  'rgb(109,125,178)',
  'rgb(143,133,187)',
  'rgb(59,170,188)',
  'rgb(198,113,108)',
  'rgb(106,142,89)',
  'rgb(102,170,53)',
  'rgb(188,139,165)',
  'rgb(161,70,137)',
  'rgb(145,185,115)',
  'rgb(157,146,156)',
  'rgb(195,111,194)',
  'rgb(82,73,111)',
  'rgb(135,82,195)',
  'rgb(111,120,75)',
  'rgb(91,144,163)',
  'rgb(124,112,62)',
  'rgb(162,115,164)',
  'rgb(73,151,191)',
  'rgb(51,134,91)',
  'rgb(127,136,152)',
  'rgb(166,126,96)',
  'rgb(142,91,138)',
  'rgb(128,123,128)',
  'rgb(138,182,74)',
  'rgb(102,102,117)',
  'rgb(79,173,166)',
  'rgb(152,188,163)',
  'rgb(124,106,199)',
  'rgb(115,144,96)',
  'rgb(154,153,110)',
  'rgb(97,94,122)',
  'rgb(95,93,63)',
  'rgb(61,103,199)',
  'rgb(85,115,148)',
  'rgb(192,177,88)',
  'rgb(199,128,197)',
  'rgb(116,185,196)',
  'rgb(174,71,196)',
  'rgb(77,100,95)',
  'rgb(79,176,132)',
  'rgb(79,169,96)',
  'rgb(71,79,115)',
  'rgb(125,89,64)',
  'rgb(122,144,134)',
  'rgb(105,63,93)',
  'rgb(139,155,182)',
  'rgb(90,197,87)',
  'rgb(80,115,79)',
  'rgb(163,101,146)',
  'rgb(160,50,117)',
  'rgb(80,161,135)',
  'rgb(158,50,80)',
  'rgb(136,98,51)',
  'rgb(84,132,98)',
  'rgb(117,180,101)',
  'rgb(72,113,195)',
  'rgb(90,93,173)',
  'rgb(177,111,132)',
  'rgb(175,92,123)',
  'rgb(162,105,128)',
  'rgb(97,88,179)',
  'rgb(67,110,127)',
  'rgb(145,91,151)',
  'rgb(80,58,113)',
  'rgb(123,170,169)',
  'rgb(139,103,144)',
  'rgb(108,129,108)',
  'rgb(74,188,156)',
  'rgb(55,150,176)',
  'rgb(179,99,53)',
  'rgb(130,78,174)',
  'rgb(138,155,62)',
  'rgb(96,153,109)',
  'rgb(164,112,104)',
  'rgb(147,125,54)',
  'rgb(77,132,133)',
  'rgb(58,114,149)',
  'rgb(77,111,122)',
  'rgb(75,172,158)',
  'rgb(167,63,124)',
  'rgb(171,152,83)',
  'rgb(98,144,61)',
  'rgb(117,72,165)',
  'rgb(102,137,78)',
  'rgb(156,132,50)',
  'rgb(139,134,171)',
  'rgb(87,84,189)',
  'rgb(148,133,72)',
  'rgb(102,83,118)',
  'rgb(72,93,52)',
  'rgb(140,54,86)',
  'rgb(196,68,132)',
  'rgb(174,191,108)',
  'rgb(127,122,50)',
  'rgb(118,155,133)',
  'rgb(82,127,67)',
  'rgb(137,75,102)',
  'rgb(162,127,77)',
  'rgb(58,134,69)',
  'rgb(114,93,187)',
  'rgb(108,182,75)',
  'rgb(98,177,86)',
  'rgb(90,91,193)',
  'rgb(155,128,149)',
  'rgb(152,162,74)',
  'rgb(129,96,89)',
  'rgb(97,115,191)',
  'rgb(193,124,143)',
  'rgb(54,188,152)',
  'rgb(180,62,170)',
  'rgb(119,180,191)',
  'rgb(194,191,146)',
  'rgb(131,166,140)',
  'rgb(184,92,50)',
  'rgb(189,123,141)',
  'rgb(90,179,120)',
  'rgb(99,142,97)',
  'rgb(168,97,76)',
  'rgb(187,79,165)',
  'rgb(87,170,56)',
  'rgb(116,144,144)',
  'rgb(53,195,62)',
  'rgb(130,182,162)',
  'rgb(77,143,66)',
  'rgb(94,96,184)',
  'rgb(109,124,68)',
  'rgb(51,109,154)',
  'rgb(137,186,97)',
  'rgb(144,151,69)',
  'rgb(161,127,185)',
  'rgb(141,195,53)',
  'rgb(61,130,104)',
  'rgb(120,139,69)',
  'rgb(171,184,103)',
  'rgb(149,93,107)',
  'rgb(140,130,110)',
  'rgb(151,92,76)',
  'rgb(191,104,56)',
  'rgb(155,53,167)',
  'rgb(60,59,136)',
  'rgb(64,149,113)',
  'rgb(152,169,109)',
  'rgb(80,166,168)',
  'rgb(179,103,188)',
  'rgb(73,104,167)',
  'rgb(113,191,196)',
  'rgb(178,93,152)',
  'rgb(160,87,177)',
  'rgb(92,68,136)',
  'rgb(52,121,92)',
  'rgb(189,61,178)',
  'rgb(191,60,111)',
  'rgb(170,154,163)',
  'rgb(102,101,193)',
  'rgb(52,136,163)',
  'rgb(55,78,167)',
  'rgb(180,103,84)',
  'rgb(95,189,192)',
  'rgb(161,183,186)',
  'rgb(184,95,140)',
  'rgb(69,89,138)',
  'rgb(124,88,134)',
  'rgb(154,50,169)',
  'rgb(196,158,71)',
  'rgb(69,59,132)',
  'rgb(164,146,71)',
  'rgb(190,185,152)',
  'rgb(63,58,65)',
  'rgb(107,132,105)',
  'rgb(196,139,89)',
  'rgb(138,61,76)',
  'rgb(172,123,191)',
  'rgb(80,151,183)',
  'rgb(144,167,92)',
  'rgb(76,167,132)',
  'rgb(64,58,115)',
  'rgb(187,111,127)',
  'rgb(76,81,147)',
  'rgb(198,91,60)',
  'rgb(118,183,191)',
  'rgb(145,168,128)',
  'rgb(152,144,153)',
  'rgb(74,107,81)',
  'rgb(109,181,85)',
  'rgb(113,81,94)',
  'rgb(100,160,132)',
  'rgb(69,140,112)',
  'rgb(107,92,124)',
  'rgb(50,150,195)',
  'rgb(112,71,119)',
  'rgb(139,152,83)',
  'rgb(129,175,147)',
  'rgb(150,180,152)',
  'rgb(103,180,115)',
  'rgb(136,54,52)',
  'rgb(72,160,64)',
  'rgb(190,175,88)',
  'rgb(68,159,146)',
  'rgb(134,117,104)',
  'rgb(74,60,182)',
  'rgb(110,194,142)',
  'rgb(72,77,161)',
  'rgb(62,54,69)',
  'rgb(137,168,160)',
  'rgb(99,100,66)',
  'rgb(129,128,52)',
  'rgb(159,110,111)',
  'rgb(134,95,174)',
  'rgb(152,59,93)',
  'rgb(148,179,166)',
  'rgb(187,59,162)',
  'rgb(72,178,116)',
  'rgb(71,112,56)',
  'rgb(115,133,78)',
  'rgb(74,107,132)',
  'rgb(155,189,88)',
  'rgb(125,101,175)',
  'rgb(176,199,194)',
  'rgb(198,106,95)',
  'rgb(74,135,131)',
  'rgb(172,112,173)',
  'rgb(174,199,171)',
  'rgb(152,152,102)',
  'rgb(106,161,74)',
  'rgb(182,199,94)',
  'rgb(112,57,142)',
  'rgb(199,113,153)',
  'rgb(69,155,177)',
  'rgb(132,171,63)',
  'rgb(53,130,168)',
  'rgb(116,86,197)',
  'rgb(53,168,197)',
  'rgb(129,145,50)',
  'rgb(152,109,128)',
  'rgb(148,163,129)',
  'rgb(94,69,116)',
  'rgb(105,65,157)',
  'rgb(126,95,178)',
  'rgb(62,105,182)',
  'rgb(184,68,131)',
  'rgb(87,117,167)',
  'rgb(140,144,66)',
  'rgb(117,196,183)',
  'rgb(146,90,63)',
  'rgb(165,131,88)',
  'rgb(83,65,144)',
  'rgb(188,154,54)',
  'rgb(160,101,129)',
  'rgb(166,70,144)',
  'rgb(146,181,65)',
  'rgb(148,94,176)',
  'rgb(63,194,83)',
  'rgb(95,136,170)',
  'rgb(78,59,158)',
  'rgb(115,115,108)',
  'rgb(97,196,173)',
  'rgb(121,185,146)',
  'rgb(90,168,60)',
  'rgb(81,157,130)',
  'rgb(189,190,155)',
  'rgb(74,120,117)',
  'rgb(94,148,130)',
  'rgb(151,196,92)',
  'rgb(100,50,196)',
  'rgb(82,190,179)',
  'rgb(88,163,56)',
  'rgb(157,94,196)',
  'rgb(179,122,58)',
  'rgb(105,55,79)',
  'rgb(190,149,141)',
  'rgb(102,143,199)',
  'rgb(112,188,112)',
  'rgb(151,179,188)',
  'rgb(66,136,178)',
  'rgb(126,62,117)',
  'rgb(148,132,50)',
  'rgb(97,135,83)',
  'rgb(130,196,126)',
  'rgb(124,194,148)',
  'rgb(88,81,159)',
  'rgb(77,85,167)',
  'rgb(110,131,69)',
  'rgb(109,159,51)',
  'rgb(107,163,168)',
  'rgb(131,187,56)',
  'rgb(160,128,69)',
  'rgb(91,79,75)',
  'rgb(177,134,54)',
  'rgb(137,182,82)',
  'rgb(189,107,149)',
  'rgb(125,135,191)',
  'rgb(134,123,158)',
  'rgb(114,171,63)',
  'rgb(141,85,126)',
  'rgb(173,87,118)',
  'rgb(135,138,178)',
  'rgb(157,162,58)',
  'rgb(132,62,92)',
  'rgb(67,193,77)',
  'rgb(132,85,152)',
  'rgb(109,163,83)',
  'rgb(162,55,149)',
  'rgb(111,176,113)',
  'rgb(129,76,162)',
  'rgb(67,88,98)',
  'rgb(180,89,76)',
  'rgb(189,68,174)',
  'rgb(192,140,195)',
  'rgb(115,166,197)',
  'rgb(51,68,158)',
  'rgb(71,138,175)',
  'rgb(161,119,96)',
  'rgb(137,109,74)',
  'rgb(136,57,93)',
  'rgb(197,54,51)',
  'rgb(108,157,128)',
  'rgb(183,152,120)',
  'rgb(52,147,77)',
  'rgb(192,160,164)',
  'rgb(128,158,139)',
  'rgb(192,164,147)',
  'rgb(128,95,134)',
  'rgb(122,83,57)',
  'rgb(194,106,171)',
  'rgb(61,145,120)',
  'rgb(51,142,172)',
  'rgb(165,136,186)',
  'rgb(90,80,117)',
  'rgb(191,96,53)',
  'rgb(72,158,173)',
  'rgb(114,153,73)',
  'rgb(144,178,151)',
  'rgb(114,87,187)',
  'rgb(151,138,74)',
  'rgb(102,176,79)',
  'rgb(92,177,85)',
  'rgb(65,93,85)',
  'rgb(155,51,105)',
  'rgb(171,196,141)',
  'rgb(193,113,174)',
  'rgb(175,146,112)',
  'rgb(144,65,192)',
  'rgb(124,94,51)',
  'rgb(137,79,131)',
  'rgb(124,164,58)',
  'rgb(100,138,92)',
  'rgb(162,105,65)',
  'rgb(138,59,184)',
  'rgb(88,55,156)',
  'rgb(92,124,150)',
  'rgb(178,195,131)',
  'rgb(165,179,77)',
  'rgb(183,186,91)',
  'rgb(161,81,114)',
  'rgb(185,130,78)',
  'rgb(193,187,152)',
  'rgb(56,80,69)',
  'rgb(147,75,142)',
  'rgb(83,54,178)',
  'rgb(145,66,140)',
  'rgb(199,54,124)',
  'rgb(111,95,104)',
  'rgb(120,130,108)',
  'rgb(164,64,124)',
  'rgb(187,101,146)',
  'rgb(150,161,153)',
  'rgb(88,128,192)',
  'rgb(84,129,193)',
  'rgb(81,168,176)',
  'rgb(69,74,84)',
  'rgb(187,156,154)',
  'rgb(141,86,62)',
  'rgb(167,159,117)',
  'rgb(134,137,84)',
  'rgb(199,138,68)',
  'rgb(182,96,59)',
  'rgb(101,179,114)',
  'rgb(173,135,195)',
  'rgb(128,161,168)',
  'rgb(89,76,58)',
  'rgb(92,162,111)',
  'rgb(72,100,147)',
  'rgb(94,171,76)',
  'rgb(155,160,195)',
  'rgb(67,75,54)',
  'rgb(99,124,125)',
  'rgb(56,80,183)',
  'rgb(79,156,192)',
  'rgb(149,117,54)',
  'rgb(85,175,181)',
  'rgb(163,76,118)',
  'rgb(134,175,194)',
  'rgb(64,73,86)',
  'rgb(94,183,65)',
  'rgb(85,81,105)',
  'rgb(144,104,174)',
  'rgb(147,130,109)',
  'rgb(163,161,156)',
  'rgb(143,72,57)',
  'rgb(149,126,138)',
  'rgb(143,80,62)',
  'rgb(59,65,179)',
  'rgb(105,129,186)',
  'rgb(99,157,101)',
  'rgb(133,102,152)',
  'rgb(153,184,130)',
  'rgb(67,82,77)',
  'rgb(89,117,167)',
  'rgb(161,143,82)',
  'rgb(132,162,93)',
  'rgb(69,160,76)',
  'rgb(164,184,67)',
  'rgb(147,174,130)',
  'rgb(182,167,81)',
  'rgb(54,88,174)',
  'rgb(136,123,169)',
  'rgb(57,109,151)',
  'rgb(75,150,56)',
  'rgb(81,103,141)',
  'rgb(118,171,196)',
  'rgb(175,93,185)',
  'rgb(59,136,137)',
  'rgb(82,163,106)',
  'rgb(128,142,149)',
  'rgb(148,172,193)',
  'rgb(186,121,96)',
  'rgb(89,140,188)',
  'rgb(137,87,199)',
  'rgb(156,70,63)',
  'rgb(55,73,152)'
].reverse();
const voiceColors = [
  '#fd7f6f',
  '#7eb0d5',
  '#b2e061',
  '#bd7ebe',
  '#ffb55a',
  '#ffee65',
  '#e5d8bd',
  '#beb9db',
  '#fdcce5',
  '#8bd3c7'
];

interface Theme {
  availablePatternColors: string[];
  colorByVoice: boolean;
  colorVoices: boolean;
  getVoiceColor: (voiceNumber: number) => string | undefined;
}

const defaultTheme = {
  availablePatternColors: patternColors.map((color) => tinycolor(color).toHexString()),
  colorByVoice: false,
  colorVoices: false,
  getVoiceColor: (voiceNumber: number) =>
    voiceNumber <= voiceColors.length ? voiceColors[voiceNumber - 1] : undefined
};

export const ThemeContext = createContext<[Theme, Dispatch<SetStateAction<Theme>>]>([
  defaultTheme,
  undefined
]);

const ThemeContextProvider = ({ children }: { children: ReactNode }) => {
  const [theme, setTheme] = useState(defaultTheme);

  return <ThemeContext.Provider value={[theme, setTheme]}>{children}</ThemeContext.Provider>;
};

export default ThemeContextProvider;
