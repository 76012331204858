import augmentationOperator from '../../assets/operators/augmentation.svg';
import deviationOperator from '../../assets/operators/deviation.svg';
import diminutionOperator from '../../assets/operators/diminution.svg';
import mirrorXOperator from '../../assets/operators/mirror-x.svg';
import mirrorYOperator from '../../assets/operators/mirror-y.svg';
import reductionToPitchOperator from '../../assets/operators/reduction-to-pitch.svg';
import reductionToRhythmOperator from '../../assets/operators/reduction-to-rhythm.svg';
import transpositionOperator from '../../assets/operators/transposition.svg';

export interface Occurrence {
  firstOffset: number;
  lastOffset: number;
  voiceNumber: number;
}

export const areOccurrencesEqual = (first: Occurrence, second: Occurrence) => {
  return (
    first.firstOffset === second.firstOffset &&
    first.lastOffset === second.lastOffset &&
    first.voiceNumber === second.voiceNumber
  );
};

export enum Operator {
  AUGMENTATION = 'augmentation',
  DEVIATION = 'deviation',
  DIMINUTION = 'diminution',
  MIRROR_X = 'mirror_x',
  MIRROR_Y = 'mirror_y',
  REDUCTION_TO_PITCH = 'reduction_to_pitch',
  REDUCTION_TO_RHYTHM = 'reduction_to_rhythm',
  TRANSPOSITION = 'transposition'
}

export const OperatorsInOrder = [
  Operator.TRANSPOSITION,
  Operator.MIRROR_X,
  Operator.MIRROR_Y,
  Operator.DIMINUTION,
  Operator.AUGMENTATION,
  Operator.REDUCTION_TO_PITCH,
  Operator.REDUCTION_TO_RHYTHM,
  Operator.DEVIATION
];

export const getIconForOperator = (operator: Operator) => {
  switch (operator) {
    case Operator.AUGMENTATION:
      return augmentationOperator;
    case Operator.DEVIATION:
      return deviationOperator;
    case Operator.DIMINUTION:
      return diminutionOperator;
    case Operator.MIRROR_X:
      return mirrorXOperator;
    case Operator.MIRROR_Y:
      return mirrorYOperator;
    case Operator.REDUCTION_TO_PITCH:
      return reductionToPitchOperator;
    case Operator.REDUCTION_TO_RHYTHM:
      return reductionToRhythmOperator;
    case Operator.TRANSPOSITION:
      return transpositionOperator;
  }
};

export const isOperatorBidirectional = (operator: Operator) => {
  switch (operator) {
    case Operator.DEVIATION:
    case Operator.REDUCTION_TO_PITCH:
    case Operator.REDUCTION_TO_RHYTHM:
      return false;
    default:
      return true;
  }
};

export interface Pattern {
  color: string;
  connectedToByOccurrences: {
    id: string;
    occurrences: Occurrence[];
  }[];
  connectedToByOperator: {
    id: string;
    byOperator: Operator;
  }[];
  description: string;
  hasBeenQueried: boolean;
  id: string;
  isLocked: boolean;
  isUserDefined: boolean;
  isVisible: boolean;
  label: string;
  length: number;
  occurrences: Occurrence[];
  uniqueOccurrencesCount: number;
  voiceNumber: number;
}

export class PatternLabelGenerator {
  private static labels = [
    'Z',
    'Y',
    'X',
    'W',
    'V',
    'U',
    'T',
    'S',
    'R',
    'Q',
    'P',
    'O',
    'N',
    'M',
    'L',
    'K',
    'J',
    'I',
    'H',
    'G',
    'F',
    'E',
    'D',
    'C',
    'B',
    'A'
  ];
  private static usedLabels = [];

  static add = (label: string) => {
    const usedLabelIndex = PatternLabelGenerator.usedLabels.findIndex(
      (usedLabel) => usedLabel === label
    );

    PatternLabelGenerator.usedLabels.splice(usedLabelIndex, 1);
    PatternLabelGenerator.labels.push(label);
    PatternLabelGenerator.labels.sort().reverse();
  };
  static next = () => {
    if (PatternLabelGenerator.labels.length === 0) PatternLabelGenerator.reset();

    const label = PatternLabelGenerator.labels.pop();

    PatternLabelGenerator.usedLabels.splice(0, 0, label);

    return label;
  };
  static reset = () => {
    PatternLabelGenerator.labels.push(...PatternLabelGenerator.usedLabels);
    PatternLabelGenerator.usedLabels = [];
  };
}
